<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  min-height: 8rem;
  display: flex;
  justify-content: space-between;
}
.item-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /deep/ .el-input__inner {
    width: 100px;
  }
  /deep/.el-input-group__append {
    background-color: beige;
  }
  /deep/ .el-input-group__prepend {
    color: red;
    background-color: #fff;
  }
  /deep/.el-input-group--append .el-input__inner {
    border-radius: 5%;
    margin-right: 5px;
  }
}
.item-row > span {
  margin: 0 10px 5px 0;
}
.except-text {
  .executioner {
    margin: 20px;
  }
  .execution-time {
    margin: 20px;
  }
}
.demonstration {
  margin: 10px 0 0 10px;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="节点编辑" v-if="isShow" @close="handleCancel">
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="handleConfirm"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="handleCancel"
      >
        关闭
      </el-button>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <div style="width: 100%">{{ treeTitle }}</div>
        <div v-for="(item, index) in input_list" :key="index">
          <div
            v-if="item.val !== null"
            :style="item.width > 200 ? 'flex-wrap:wrap' : ''"
            style="display: flex; align-items: center"
          >
            <span v-if="item.pre" style="color: red; flex-shrink: 0">{{ item.pre }}</span>
            <el-input
              style="margin-left: 5px; margin-right: 2px"
              class="TreeInfo"
              type="textarea"
              autosize
              @input="changeWidth(item)"
              :style="`width: ${item.width}px`"
              v-model.trim="item.val"
              @keyup.enter.native="nextFocus($event)"
              placeholder=""
            ></el-input>
          </div>
          <span v-else style="color: red; margin-right: 5px">{{ item.pre }}</span>
        </div>
      </div>
    </div>

    <div class="except-text">
      <div class="executioner">
        <span class="demonstration">执行者（可多选、可搜索）</span>
        <el-select
          value-key="label"
          v-model="executeNames"
          multiple
          filterable
          size="mini"
          placeholder="请选择执行者"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="execution-time">
        <div>
          <span class="demonstration" style="margin-right: 10px">执行时间</span>
          <el-date-picker
            v-model="executeDate"
            type="datetime"
            align="center"
            placeholder="选择日期"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
          ></el-date-picker>
        </div>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'AddTreeInfoDialog',
  components: {
    CmDialogBorder
  },

  data() {
    return {
      input_list: [],
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      options: [],
      tempFilterOptions: [],
      executeNames: [],
      executeDate: null,
      treeData: [],
      info: null,
      treeTitle: '',
      inputDoms: null
    }
  },
  methods: {
    changeWidth(item) {
      if (item.val.length * 10 > 120) {
        if (item.val.length * 10 < 310) {
          item.width = item.val.length * 10
        }
      }
    },
    Show(val) {
      var time = new Date()
      time.setHours(time.getHours() + 8)
      time = time.toISOString()
      time = time.replace('T', ' ')
      time = time.substr(0, 19)
      this.executeDate = time
      this.isShow = true
      this.input_list = []

      this.treeData = val.data
      this.info = val
      for (let j = 0; j < this.treeData.length; j++) {
        const ele = this.treeData[j]
        if (ele.nodeTextOldStyle.includes('|')) {
          let s = ele.nodeTextOldStyle
          var k, i
          for (i = 0, k = 0; i < s.length; i++) {
            if (s[i] == '|' && i + 1 < s.length) {
              this.input_list.push({
                pre: s.substr(k, i - k),
                val: '',
                id: ele.id,
                width: 120
              })
              k = i + 1
            } else {
              if (s[i] == '|' && i + 1 === s.length) {
                this.input_list.push({
                  pre: s.substr(k, i - k),
                  val: '',
                  id: ele.id,
                  width: 120
                })
              } else {
                if (i + 1 === s.length) {
                  this.input_list.push({
                    pre: s.substr(k, s.length - k),
                    val: null
                  })
                }
              }
            }
          }
        } else {
          this.treeTitle = this.treeTitle + ele.nodeTextOldStyle
        }
      }

      this.getUserName()

      setTimeout(() => {
        this.changeInputDoms()
        this.inputDoms = this.changeInputDoms()
        if (this.inputDoms.length > 0) {
          this.$nextTick(() => {
            this.inputDoms[0].focus()
          })
        }
      }, 300)
    },

    handleConfirm() {
      for (let i = 0; i < this.input_list.length; i++) {
        const element = this.input_list[i]
        if (element.val === '') {
          return this.$message.error('有未填项,无法保存')
        }
      }
      if (this.executeNames.length === 0) {
        return this.$message.error('请选择执行者')
      }
      if (this.executeDate === '') {
        return this.$message.error('请填写执行时间')
      }
      this.info.jsonArgs = {}
      this.info.executeNames = this.executeNames
      this.info.executeDate = this.executeDate
      for (let j = 0; j < this.treeData.length; j++) {
        const element = this.treeData[j]
        let itemVal = ''
        for (let i = 0; i < this.input_list.length; i++) {
          const ele = this.input_list[i]
          if (element.id === ele.id) {
            if (ele.val !== null) {
              itemVal = itemVal + ele.val + '#'
              element.fieldValue = itemVal
            }
            if (element.vars?.length === 1) {
              element.vars.forEach((v) => {
                this.info.jsonArgs[v.varname] = ele.val
              })
            } else if (element.vars?.length > 1) {
              var arr = element.fieldValue.split('#').filter(Boolean)
              element.vars.forEach((v, index) => {
                this.info.jsonArgs[v.varname] = arr[index]
              })
            }
          }
        }
      }

      this.$emit('save', this.info)
      this.handleCancel()
    },
    handleCancel() {
      this.treeTitle = ''
      this.isShow = false
    },
    getUserName() {
      this.executeNames = []
      this.$api.get('/v1/webconsole/user/me').then(
        (res) => {
          if (res.data && res.data.data) {
            for (let i = 0; i < this.tempFilterOptions.length; i++) {
              const element = this.tempFilterOptions[i]
              if (res.data.data.userID === element.userID) {
                this.executeNames.push(res.data.data.userName)
              }
            }
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    init() {
      this.$api.get('/v1/webconsole/study/nurses').then(
        (res) => {
          if (res.data && res.data.data) {
            let arr = []
            res.data.data.map((item) => {
              arr.push({
                label: item.userName,
                value: item.userName
              })
            })
            this.options = arr
            this.tempFilterOptions = res.data.data
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    changeInputDoms() {
      const inputDoms = document.querySelectorAll('.item-row .TreeInfo .el-textarea__inner')
      inputDoms.forEach((item, index) => {
        item.setAttribute('data-index', index)
      })
      return inputDoms
    },
    nextFocus(event) {
      const index = event.target.getAttribute('data-index')
      const nextIndex = parseInt(index) + 1
      const length = this.inputDoms.length
      if (nextIndex < length) {
        this.inputDoms[nextIndex].focus()
      } else {
        this.inputDoms[0].focus()
      }
    }
  },

  watch: {},
  mounted() {
    this.init()
  }
}
</script>
