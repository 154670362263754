<style lang="less" scoped>
.custom-tree-node {
  font-size: 13px;
  .key-node {
    color: #9b59b6;
  }
  .normal {
    color: #000;
  }
}
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #b8d4f0 !important;
}
</style>
<template>
  <el-tree
    class="cm-tree"
    :data="treeResult"
    :props="props"
    @current-change="handleCheckChange"
    :expand-on-click-node="false"
    :default-expand-all="true"
  >
    <span class="custom-tree-node" slot-scope="{ data }">
      <span :class="data.data.keyNode ? 'key-node' : 'normal'">{{ data.nodeText }}</span>
    </span>
  </el-tree>
</template>

<script>
export default {
  name: 'CmTree',
  components: {},
  props: {
    packageIndex: {
      type: [String, Number],
      require: true
    },
    isReadOnly: {
      type: Boolean
    }
  },
  mounted() {
    this.getTreeData()
  },
  data() {
    return {
      renderDatas: [],
      treeData: [],
      treeResult: [],
      rootNodeName: '',
      props: {
        children: 'child',
        label: 'nodeText'
      },
      treeClickCount: 0
    }
  },
  methods: {
    handleCheckChange(data, node) {
      this.treeClickCount++
      if (this.treeClickCount >= 2) {
        return
      }
      //计时器,计算300毫秒为单位,可自行修改
      this.timer = window.setTimeout(() => {
        if (this.treeClickCount == 1) {
          //把次数归零
          this.treeClickCount = 0
          return
        } else if (this.treeClickCount > 1) {
          //把次数归零
          this.treeClickCount = 0
          if (this.isReadOnly) {
            return this.$message.warning('不可操作！')
          }
          //双击事件
          var time = new Date()
          time.setHours(time.getHours() + 8)

          time = time.toISOString()

          time = time.replace('T', ' ')

          time = time.substr(0, 19)

          let text = []
          let arr = []
          while (node && !Array.isArray(node.data)) {
            text.push(node.data.nodeText)
            arr.push(node.data.data)
            node = node.parent
          }
          console.log(data)
          console.log(arr)

          this.$emit('func', {
            content: text.reverse().join(''),
            data: arr,
            executeDate: time,
            nodeIndex: data.id,
            label: data.nodeText
          })
        }
      }, 300)
    },

    trans(arr) {
      if (arr) {
        for (let obj of arr) {
          obj['nodeText'] = obj['data'].nodeTextOldStyle
          if (obj['child']) {
            this.trans(obj['child'])
          }
        }
        return arr
      }
    },

    getTreeData() {
      this.$api.get(`/v1/webconsole/nursing/package/${this.packageIndex}`).then(
        (res) => {
          if (res.data && res.data.data) {
            this.treeData = res.data.data.child
            if (res.data.data.child) {
              this.treeResult = this.trans(this.treeData[0].child)
            } else {
              this.treeResult = []
            }
          }
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }
}
</script>

// 弃用
