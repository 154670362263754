<style scoped lang="less">
.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.top-item-row {
  margin: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-row {
  margin: 1rem 2rem;
  display: flex;
  /deep/ .el-input__inner {
    width: 100px;
  }
}
.item-row > span {
  width: 60px;
  margin: 5px 0;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="手工记录" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>心率HR</span>
        <div>
          <el-input
            placeholder="请输入"
            type="text"
            v-model="HR"
            class="noteInfo"
            @keyup.enter.native="nextFocus($event)"
            size="mini"
          >
            <template slot="append">bpm</template>
          </el-input>
        </div>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>收缩压</span>
        <div>
          <el-input
            placeholder="请输入"
            type="text"
            v-model="BB"
            class="noteInfo"
            @keyup.enter.native="nextFocus($event)"
            size="mini"
          >
            <template slot="append">mmHg</template>
          </el-input>
        </div>
      </div>
      <span>/</span>
      <div class="item-row">
        <span>舒张压</span>
        <div>
          <el-input
            placeholder="请输入"
            type="text"
            v-model="PP"
            class="noteInfo"
            @keyup.enter.native="nextFocus($event)"
            size="mini"
          >
            <template slot="append">mmHg</template>
          </el-input>
        </div>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>SPO2</span>
        <div>
          <el-input
            placeholder="请输入"
            type="text"
            v-model="SPO2"
            class="noteInfo"
            @keyup.enter.native="nextFocus($event)"
            size="mini"
          >
            <template slot="append">%</template>
          </el-input>
        </div>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>呼吸RR</span>
        <div>
          <el-input
            placeholder="请输入"
            type="text"
            v-model="RR"
            class="noteInfo"
            @keyup.enter.native="nextFocus($event)"
            size="mini"
          >
            <template slot="append">rpm</template>
          </el-input>
        </div>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'noteDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      isOk: false,
      HR: '',
      BB: '',
      PP: '',
      SPO2: '',
      RR: '',
      inputDoms: null
    }
  },
  methods: {
    Show(bloodVessel) {
      setTimeout(() => {
        this.changeInputDoms()
        this.inputDoms = this.changeInputDoms()
        this.$nextTick(() => {
          this.inputDoms[0].focus()
        })
      }, 300)
      this.isShow = true
    },
    changeInputDoms() {
      const inputDoms = document.querySelectorAll('.item-row .noteInfo .el-input__inner')
      inputDoms.forEach((item, index) => {
        item.setAttribute('data-index', index)
      })
      return inputDoms
    },
    nextFocus(event) {
      const index = event.target.getAttribute('data-index')
      const nextIndex = parseInt(index) + 1
      const length = this.inputDoms.length
      if (nextIndex < length) {
        this.inputDoms[nextIndex].focus()
      } else {
        this.inputDoms[0].focus()
      }
    },
    Save() {
      if (this.HR !== '') {
        this.isOk = true
      }
      if (this.BB !== '') {
        this.isOk = true
      }
      if (this.SPO2 !== '') {
        this.isOk = true
      }
      if (this.RR !== '') {
        this.isOk = true
      }
      if (this.isOk === false) {
        return this.$message({
          message: '请填写至少一项数据',
          type: 'warning'
        })
      }
      if (this.BB !== '' && this.PP === '') {
        return this.$message({
          message: '请填写舒张压',
          type: 'warning'
        })
      }
      if (this.PP !== '' && this.BB === '') {
        return this.$message({
          message: '请填写收缩压',
          type: 'warning'
        })
      }
      this.$api
        .post(`/v1/webconsole/niecg/add/${this.$route.query.operationId}?stage=sz`, {
          hr: this.HR,
          bp_dia: this.BB,
          bp_sys: this.PP,
          spo2: this.SPO2,
          rr: this.RR
        })
        .then((res) => {
          if (res.data && res.data.data) {
            this.HR = ''
            this.BB = ''
            this.PP = ''
            this.SPO2 = ''
            this.RR = ''
            this.$emit('save')
            this.isShow = false
            this.isOk = false
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          }
        })
    },

    Close() {
      this.HR = ''
      this.BB = ''
      this.PP = ''
      this.SPO2 = ''
      this.RR = ''
      this.isShow = false
      this.isOk = false
    }
  }
}
</script>
