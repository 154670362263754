<style scoped lang="less">
.main-content {
  background: #eff0f7;
}
.table-box {
  margin-top: 10px;
  margin-left: 10px;
  border-top-left-radius: 8px;
  /deep/.el-card__body {
    padding: 0;
    display: flex;
    // align-items: center;
    // justify-content: space-around;
    height: calc(100vh - 116px);
  }
}
.report-table {
  width: 50%;
  height: 100%;
  /deep/ .el-table .cell {
    white-space: pre-line;
    .table-data-picker {
      .el-date-editor {
        width: 170px;
      }
    }
  }
  .common-combo-left {
    width: 100%;
    height: 50%;
    .el-table {
      border-top: none;
      border-left: none;
    }
  }
  .common-combo-left-top {
    width: 100%;
    margin-top: 1%;
    height: 49%;

    .inner-tabs-left {
      width: 100%;
      margin: -1px;
      height: calc(100% - 2px);
      border: 1px solid black;
      border-bottom: none;
    }
    /deep/ .el-tabs--border-card {
      box-shadow: none;
    }
  }
}
.ecg-box {
  width: 49%;
  height: calc(100% - 2px);
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
  border-right: none;

  margin-left: 8px;
  flex: 1;
  .el-table {
    border: 1px solid #ebeef5;
    /deep/ .reportRow {
      background: #ebeef5;
    }
  }
  .selectTime {
    height: 30px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    .el-input__inner {
      border: 1px solid black;
      color: #606266;
      height: 30px;
      line-height: 30px;
    }
  }
}

.inner-tabs {
  margin: 0 5px;
  border: 1px solid black;
  height: 100%;
}
.crt-content {
  display: flex;
  justify-content: space-between;
  .btn-box {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 1400px) {
  .crt-content {
    height: 64px;
  }
}
.content {
  min-width: 27%;
  margin-right: 20px;
  .el-range-editor--mini {
    margin: 0 10px;
  }
}
.main-content {
  height: 100%;
}
</style>
<template>
  <div class="main-content">
    <!-- 生命体征 -->
    <div class="crt-content">
      <div class="btn-box">
        <el-button
          style="margin-left: 5px"
          icon="el-icon-s-platform"
          class="commonBtn"
          @click="infoPreview"
          size="mini"
          type="primary"
        >
          报告预览
        </el-button>
        <el-button
          icon="el-icon-edit"
          class="commonBtn"
          @click="reportSign"
          size="mini"
          type="success"
        >
          {{ signStatus ? '解签' : '报告签名' }}
        </el-button>
        <el-button
          v-show="!isReadOnly"
          type="danger"
          class="common-button commonBtn"
          icon="el-icon-delete"
          v-debounce="open_deletePreRecord"
        >
          删除
        </el-button>
        <el-checkbox-group
          style="display: flex"
          v-model="headerTitle"
          :style="isReadOnly ? 'margin:0 20px' : ''"
          @change="batchOperate"
        >
          <el-checkbox label="estimate">护理评估</el-checkbox>
          <el-checkbox label="intervene">护士介入治疗</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="content">
        <el-tag style="margin-right: 10px">
          <span style="margin-left: 15px">自动记录生命体征</span>
          <el-input-number
            style="margin: 0 5px"
            placeholder="60"
            v-model="is_Vital_signs_time"
            label="监听间隔"
            :min="1"
            :step="1"
            size="mini"
            :disabled="is_Vital_signs_visible || isReadOnly"
          />
          <span style="margin-right: 20px">min</span>
          <span>开始记录:</span>
          <el-switch
            style="margin: 0 20px"
            :disabled="isReadOnly"
            v-model="is_Vital_signs"
            active-color="#13ce66"
            @change="change_is_Vital_signs"
          ></el-switch>
        </el-tag>
      </div>
    </div>
    <el-card class="table-box">
      <div class="report-table">
        <div class="common-combo-left">
          <el-table
            :data="reportData"
            :header-cell-style="{ padding: 0 }"
            style="width: 100%"
            border
            height="100%"
            highlight-current-row
            @current-change="handleCurrentChange"
            @row-dblclick="handleDoubleClick"
          >
            <el-table-column
              header-align="center"
              fixed="left"
              label="护理内容"
              min-width="150"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.hr" type="info" size="mini">
                  HR:{{ scope.row.hr }}bpm
                </el-tag>
                <span v-if="scope.row.hr">&ensp;</span>
                <el-tag v-if="scope.row.bp" type="info" size="mini">
                  BP:{{ scope.row.bp }}mmHg
                </el-tag>
                <span v-if="scope.row.hr">&ensp;</span>
                <el-tag v-if="scope.row.spo" type="info" size="mini">
                  SPO2:{{ scope.row.spo }}%
                </el-tag>
                <span v-if="scope.row.hr">&ensp;</span>
                <el-tag v-if="scope.row.rr" type="info" size="mini">
                  RR:{{ scope.row.rr }}rpm
                </el-tag>
                <span v-if="scope.row.hr">&ensp;</span>
                <span>{{ scope.row.content }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="执行者"
              width="150"
              align="center"
              prop="executeName"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div>
                  <el-select
                    v-if="scope.row.executeNameIsShowEdit"
                    v-model="scope.row.oldExecuteName"
                    multiple
                    size="mini"
                    placeholder="请选择执行者"
                    @change="saveExecuteNameReportRow(scope.row)"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                  <span v-else @click="editExecuteNameReportRow(scope.row)">
                    {{ scope.row.executeName }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="executeDate" label="执行时间" width="190" align="center">
              <template slot-scope="scope">
                <div class="table-data-picker">
                  <el-date-picker
                    v-if="scope.row.executeDateIsShowEdit"
                    v-model="scope.row.oldExecuteDate"
                    type="datetime"
                    align="center"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    @change="saveExecuteDateReportRow(scope.row)"
                    @blur="initReportRow"
                  ></el-date-picker>
                  <span v-else @click="editExecuteDateReportRow(scope.row)">
                    {{ scope.row.executeDate?.substring(0, 16) }}
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="common-combo-left-top">
          <el-tabs class="inner-tabs-left" type="border-card">
            <el-tab-pane v-for="item in AllPackageData" :key="item.uuid" :label="item.packageTitle">
              <cm-tree
                :packageIndex="item.uuid"
                @func="handleTreeClick"
                :isReadOnly="isReadOnly"
              ></cm-tree>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <!-- 右边监护仪数据 -->
      <div class="ecg-box">
        <div class="selectTime">
          <el-button
            v-show="!isReadOnly"
            style="margin: 0 10px"
            type="primary"
            class="common-button commonBtn"
            icon="el-icon-edit"
            @click="noteHandle"
          >
            手工记录
          </el-button>
          <el-button
            v-show="!isReadOnly"
            style="margin: 0 10px"
            type="primary"
            class="common-button commonBtn"
            icon="el-icon-search"
            @click="getECGTimeInfo"
          >
            查询
          </el-button>
          <el-button
            v-show="!isReadOnly"
            style="margin-right: 10px"
            type="primary"
            class="common-button commonBtn"
            icon="el-icon-refresh"
            @click="getECGTimeInfo('clear')"
          >
            重置
          </el-button>
          <span style="margin-right: 5px; white-space: nowrap">起始日期:</span>
          <el-date-picker
            v-model="beginDate"
            type="datetime"
            align="center"
            placeholder="选择日期时间"
          ></el-date-picker>
          <span style="margin: 0 5px 0 15px; white-space: nowrap">结束日期:</span>
          <el-date-picker
            v-model="endDate"
            type="datetime"
            align="center"
            placeholder="选择日期时间"
          ></el-date-picker>
        </div>
        <el-table
          ref="multipleTableFrag"
          :header-cell-style="{ padding: 0 }"
          :data="ecgData"
          style="width: 100%"
          height="calc(100% - 40px)"
          border
        >
          <el-table-column
            header-align="center"
            label="时间"
            width="150"
            fixed="left"
            align="center"
            prop="recordDate"
          ></el-table-column>
          <el-table-column
            header-align="center"
            label="心率HR"
            width="80"
            align="center"
            prop="hr"
          ></el-table-column>
          <el-table-column header-align="center" label="血压BP" width="80" align="center" prop="bp">
            <template slot-scope="scope">
              <div v-if="scope.row.bpSys && scope.row.bpDia">
                {{ scope.row.bpSys }}/{{ scope.row.bpDia }}
              </div>
              <div v-else>{{ scope.row.bp }}</div>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            label="SPO2"
            align="center"
            prop="spo2"
            width="80"
          ></el-table-column>
          <el-table-column
            header-align="center"
            label="呼吸RR"
            width="80"
            align="center"
            prop="rr"
          ></el-table-column>
          <el-table-column
            header-align="center"
            label="病情及特殊处理"
            align="center"
            show-overflow-tooltip
            min-width="150"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.niOperateTitle"
                size="mini"
                type="text"
                :disabled="isReadOnly"
              >
                事件记录：{{ scope.row.niOperateTitle }}
              </el-button>
              <el-button
                v-else
                size="mini"
                type="text"
                :disabled="isReadOnly"
                @click="specialHandle(scope.row, 'ecg')"
              >
                突发事件处理
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                :disabled="isReadOnly"
                @click="handleAddECG(scope.row)"
              >
                添加到报告
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 签名 -->
    <sign-report
      :operation="operation"
      type="Nursing"
      @signComplete="signComplete"
      ref="SignReport"
    />
    <!-- pdf -->
    <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
      <div>
        <iframe :src="this.report_address" style="height: 890px; width: 100%"></iframe>
      </div>
    </el-dialog>

    <NoteDialog ref="noteDialog" @save="noteClose" />
    <SpecialOperation @dialogSave="dialogSave" ref="specialOperation" />
    <AddTreeInfoDialog @save="addTreeInfoSave" ref="addTreeInfoDialog" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CmTree from './components/CmTree.vue'
import NoteDialog from './components/NoteDialog'
import SpecialOperation from './components/SpecialOperation.vue'
import AddTreeInfoDialog from './components/AddTreeInfoDialog.vue'
import SignReport from '../components/SignReport.vue'

import { PDF_HOST } from '../../../utils/globalVariable'

export default {
  name: 'Nursing',
  components: {
    CmTree,
    AddTreeInfoDialog,
    NoteDialog,
    SpecialOperation,
    SignReport
  },
  data() {
    return {
      // 通用表单
      menuListData: null,
      report_address: '',
      dialogVisible: false,
      signStatus: false,
      is_Vital_signs: false,
      reportData: [],
      options: [],
      allPackageUrl: '/v1/webconsole/nursing/packages/list', // 获取护理套餐
      AllPackageData: [],
      is_Vital_signs_time: 10,
      is_Vital_signs_visible: false,
      is_Vital_signs_timer: null,
      is_Vital_signs_timer2: null,
      // 是否可编辑
      isReadOnly: false,
      nodeIndex: undefined,
      reportIndex: null,
      // 监护仪数据
      ecgData: [],
      beginDate: '',
      endDate: '',
      // 选中的监护仪列表数据
      multipleSelection: [],
      selectEcgColumn: null,
      // 监护仪按钮状态  调用接口禁止操作
      executors: [],
      headerTitle: ['estimate', 'intervene'],
      instanceName: '',
      operation: 'Nursing'
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {
    currentPatient: {
      handler(val) {
        if (val) {
          this.getListData()
          this.get_is_Vital_signs()
          this.getECGPortInfo()
        }
      },
      immediate: true
    },
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {
    this.getListData()
    this.getPackageData(this.allPackageUrl)
    this.getNurses()
  },
  methods: {
    // 追加数据
    handleAddECG(row) {
      this.$confirm('是否将本条数据添加到体征报告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.addECG(row)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    addECG(row) {
      let params = {
        hr: row?.hr,
        spo2: row.spo2,
        rr: row?.rr,
        bp_sys: row.bpSys,
        bp_dia: row.bpDia
      }
      this.$api
        .post(`/v1/webconsole/niecg/add/${this.$route.query.operationId}?stage=sz`, params)
        .then((res) => {
          if (res.data && res.data.data) {
            this.getListData()
            // 再次调右边接口 更新页面数据
            this.getECGPortInfo()
          }
        })
    },

    // 手工记录
    noteHandle() {
      this.$refs.noteDialog.Show()
    },
    noteClose() {
      this.getListData()
      this.getECGPortInfo()
    },

    // 添加特殊处理
    specialHandle(row, type) {
      this.selectEcgColumn = row
      this.$refs.specialOperation.Show(this.selectEcgColumn, type)
    },
    addTreeInfoSave(val) {
      let data = {
        packageId: val.data[0].packageId,
        nodeId: val.nodeIndex,
        jsonArgs: val.jsonArgs || null,
        executeName: val.executeNames || this.executors,
        executeDate: val.executeDate
      }
      this.$api
        .post(`/v1/webconsole/nursing/package/add/${this.$route.query.operationId}?stage=sz`, data)
        .then((res) => {
          this.$message.success(res.data.msg)
          this.dialogSave()
        })
    },
    handleTreeClick(arr) {
      console.log(arr)
      if (arr.content.includes('|')) {
        this.$refs.addTreeInfoDialog.Show(arr)
      } else {
        this.executors = []
        this.$api.get('/v1/webconsole/user/me').then(
          (res) => {
            if (res.data && res.data.data) {
              this.executors.push(res.data.data.userName)
              this.addTreeInfoSave(arr)
            }
          },
          (err) => {
            console.log(err)
          }
        )
      }
    },
    handleDoubleClick(event) {
      // if (this.isReadOnly) {
      //   return this.$message.warning('不可操作！')
      // }
      // this.$api.get(`/v1/webconsole/nursing/report/${event.reportIndex}`).then((res) => {
      //   if (res.data && res.data.data) {
      //     console.log(res.data.data)
      //   }
      // })
      // // 指标项
      // this.$api.get(`/v1/webconsole/nursing/report/data/${event.reportIndex}`).then((res) => {
      //   if (res.data && res.data.data) {
      //     console.log(res.data.data)
      //   }
      // })
      // this.selectEcgColumn = event
      // if (this.selectEcgColumn.reportIndex) {
      //   this.$api
      //     .get(`/v1/webconsole/nireportinfodetail`, {
      //       reportIndex: this.selectEcgColumn.reportIndex
      //     })
      //     .then((res) => {
      //       if (res.data && res.data.data) {
      //         if (res.data.data.niReportEcg !== null) {
      //           this.$refs.specialOperation.Show(this.selectEcgColumn)
      //         } else {
      //           this.$refs.addTreeInfoDialog.Show(res.data.data)
      //         }
      //       }
      //     })
      // }
    },
    getListData() {
      if (this.currentPatient) {
        this.$api
          .get(
            `/v1/webconsole/nursing/reports/${this.$route.query.operationId}?type=${this.instanceName}`
          )
          .then((res) => {
            if (res.data && res.data.data) {
              this.reportData = res.data.data.map((item) => {
                return {
                  ...item,
                  executeNameIsShowEdit: false,
                  executeDateIsShowEdit: false,
                  oldExecuteName: [],
                  oldExecuteDate: item.executeDate
                }
              })
              if (this.$route.meta?.noEdit) {
                this.isReadOnly = this.$route.meta.noEdit
              }
            }
          })
      }
    },

    editExecuteNameReportRow(val) {
      this.reportData.forEach((row) => {
        row.executeNameIsShowEdit = false
        row.executeDateIsShowEdit = false
      })
      if (val.executeName.includes(',')) {
        val.oldExecuteName = val.executeName.split(',')
      } else {
        val.oldExecuteName = []
        val.oldExecuteName.push(val.executeName)
      }
      val.executeNameIsShowEdit = !val.executeNameIsShowEdit
    },

    editExecuteDateReportRow(val) {
      this.reportData.forEach((row) => {
        row.executeNameIsShowEdit = false
        row.executeDateIsShowEdit = false
      })
      val.executeDateIsShowEdit = !val.executeDateIsShowEdit
    },

    initReportRow() {
      this.reportData.forEach((row) => {
        row.executeDateIsShowEdit = false
      })
    },

    saveExecuteNameReportRow(val) {
      let formData = new FormData()
      if (val.oldExecuteName.length > 0) {
        if (val.oldExecuteName.length === 1) {
          formData.append('executeNames', val.oldExecuteName[0])
        } else {
          formData.append('executeNames', val.oldExecuteName.join(','))
        }
      } else {
        return this.$message.warning('请选择执行者')
      }
      formData.append('executeTime', val.executeDate)

      this.$api
        .post(`/v1/webconsole/nursing/report/update/${val.reportIndex}`, formData)
        .then(() => {
          this.$message.success('保存成功')
          this.getListData()
        })

      val.executeNameIsShowEdit = !val.executeNameIsShowEdit
    },
    saveExecuteDateReportRow(val) {
      let formData = new FormData()
      if (val.oldExecuteDate) {
        formData.append('executeTime', val.oldExecuteDate)
      } else {
        return this.$message.warning('请选择执行时间')
      }
      formData.append('executeNames', val.executeName)
      this.$api
        .post(`/v1/webconsole/nursing/report/update/${val.reportIndex}`, formData)
        .then(() => {
          this.$message.success('保存成功')
          this.getListData()
        })

      val.executeDateIsShowEdit = !val.executeDateIsShowEdit
    },
    getNurses() {
      this.$api.get('/v1/webconsole/study/nurses').then(
        (res) => {
          if (res.data && res.data.data) {
            let arr = []
            res.data.data.map((item) => {
              arr.push({
                label: item.userName,
                value: item.userName
              })
            })
            this.options = arr
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },

    handleCurrentChange(val) {
      this.currentRow = val
    },
    batchOperate(val) {
      if (val && val.length === 1) {
        for (let i = 0; i < val.length; i++) {
          const e = val[i]
          if (e === 'estimate') {
            this.instanceName = 'hlpg'
          }
          if (e === 'intervene') {
            this.instanceName = 'jrzl'
          }
        }
        this.getListData()
      } else {
        this.instanceName = ''
        this.getListData()
      }
    },
    open_deletePreRecord() {
      if (!this.currentRow) return
      this.$confirm('此操作将删除该条报告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deletePreRecord()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    deletePreRecord() {
      this.$api.delete(`/v1/webconsole/nursing/report/delete/${this.currentRow.reportIndex}`).then(
        () => {
          this.reportData = this.reportData.filter(
            (item) => item.reportIndex !== this.currentRow.reportIndex
          )
          this.getECGPortInfo()
          this.currentRow = null
          this.$message.success('删除成功')
        },
        (error) => console.log(error)
      )
    },
    getPackageData(url) {
      this.$api.get(url).then(
        (res) => {
          this.AllPackageData = res.data.data
        },
        (err) => {
          console.log(err)
        }
      )
    },
    //判断是否在监听生命体征
    get_is_Vital_signs() {
      if (this.currentPatient) {
        this.axios
          .get(`/v1/webconsole/niecg/timer/status/${this.$route.query.operationId}`)
          .then((res) => {
            if (res.data && res.data.data) {
              if (res.data.data.taskStatus == 2) {
                this.$message.success(res.data.data.msg)
                this.is_Vital_signs = true
                this.is_Vital_signs_visible = true
                this.is_Vital_signs_time = res.data.data.period / 60
                this.is_Vital_signs_timer2 = setInterval(() => {
                  this.getNireportECG()
                }, 3 * 1000)
              } else if (res.data.data.taskStatus == 1) {
                this.is_Vital_signs_visible = false
              } else {
                this.is_Vital_signs_visible = false
              }
            }
          })
      }
    },
    // 开始监听生命体征并获取报告
    change_is_Vital_signs() {
      if (this.is_Vital_signs) {
        this.is_Vital_signs_visible = true
        this.axios
          .post(
            `/v1/webconsole/niecg/timer/start/${this.$route.query.operationId}?period=${
              this.is_Vital_signs_time * 60
            }`
          )
          .then(
            (res) => {
              if (res.data.data) {
                if (res.data.data.taskStatus === 2) {
                  this.$message.success(res.data.data.msg)
                  this.is_Vital_signs = true
                  this.is_Vital_signs_visible = true
                  this.is_Vital_signs_timer2 = setInterval(() => {
                    this.getNireportECG()
                  }, 3 * 1000)
                } else {
                  this.$message.warning(res.data.data.msg)
                  this.is_Vital_signs = false
                  this.is_Vital_signs_visible = false
                }
                this.getListData()
              } else {
                this.is_Vital_signs = false
                return this.$message.warning(res.data.msg)
              }
            },
            () => {
              this.is_Vital_signs = false
              return this.$message.error('监听出错!')
            }
          )
      } else {
        this.is_Vital_signs_visible = false
        this.axios
          .post(`/v1/webconsole/niecg/timer/stop/${this.$route.query.operationId}`)
          .then((res) => {
            clearInterval(this.is_Vital_signs_timer)
            clearInterval(this.is_Vital_signs_timer2)

            return this.$message({
              message: res.data.msg,
              type: 'warning'
            })
          })
      }
    },
    rowStyle({ row }) {
      if (row.reportIndex !== null) {
        return 'reportRow'
      }
    },
    getECGTimeInfo(val) {
      let formData = new FormData()
      if (val === 'clear') {
        this.beginDate = null
        this.endDate = null
      } else {
        formData.append('beginDate', this.beginDate)
        formData.append('endDate', this.endDate)
      }

      this.$api
        .post(`/v1/webconsole/niecg/query_ecgs/${this.$route.query.operationId}`, formData)
        .then((res) => {
          if (res.data && res.data.data) {
            this.ecgData = res.data.data
          }
        })
    },
    // 获取体征报告数据
    getECGPortInfo() {
      this.$api
        .post(`/v1/webconsole/niecg/ni_ecgs/${this.$route.query.operationId}`)
        .then((res) => {
          if (res.data && res.data.data) {
            res.data.data.forEach((item) => {
              item.time = item.recordDate
            })
            this.ecgData = res.data.data
          }
        })
    },
    //
    getNireportECG() {
      this.$api.post(`/v1/webconsole/niecg/ecg/${this.$route.query.operationId}`).then((res) => {
        if (res.data && res.data.data) {
          let arr = [...res.data.data, ...this.ecgData]
          for (let i = 0; i < arr.length; i++) {
            for (let j = i + 1; j < arr.length; j++) {
              if (arr[i].time === arr[j].time) {
                arr.splice(j, 1)
                j-- // 保证j的值自加后不变
              }
            }
          }
          this.ecgData = arr
        }
      })
    },
    // 保存成功
    dialogSave(data) {
      // 用新选择得特殊处理方式title
      if (data && data.operateTitle) {
        this.selectEcgColumn.niOperateTitle = data.operateTitle
      }
      this.getListData()
      // 再次调右边接口 更新页面数据
      this.getECGPortInfo()
    },
    /*----------------          报告预览签名        -------------*/
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
          }
        })
      }
      this.getReportInfo()
    },
    getReportInfo() {
      this.$api.get(`/v1/webconsole/report/list/nursing/${this.info.uuid}`).then((res) => {
        if (res.data.status === 200) {
          if (res.data.data?.signed === 'Y') {
            this.signStatus = true
          }
        }
      })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this.$refs.SignReport.getReportSignType()
      }
    },
    signComplete(val) {
      this.signStatus = val
    },
    // 报告
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api.get(`/v1/webconsole/report/list/nursing/${this.info.uuid}`).then(
        (res) => {
          pdfLoading.close()
          if (!res.data.data || res.data.data?.signed === 'N') {
            // 生成手术报告单
            this.$api
              .get(`/v1/webconsole/report/save/nursing/${this.info.uuid}`)
              .then(
                (response) => {
                  pdfLoading.close()
                  if (response.data && response.data.data) {
                    if (!response.data.data.pdfFileName) {
                      return this.$message.info('暂无报告')
                    }
                    var s = encodeURI(response.data.data.pdfFileName)
                    if (response.data.data.pdfFileName.includes('http')) {
                      this.report_address = s
                    } else {
                      this.report_address =
                        PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                    }
                    this.dialogVisible = true
                  } else {
                    return this.$message.info('暂无报告')
                  }
                },
                () => {
                  pdfLoading.close()
                  return this.$message.error('生成报告出错！')
                }
              )
              .catch(() => {
                pdfLoading.close()
                return this.$message.error('生成报告出错！')
              })
          } else {
            var s = encodeURIComponent(res.data.data.pdfFileName)
            if (res.data.data.pdfFileName.includes('http')) {
              this.report_address = s
            } else {
              this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
            }
            this.dialogVisible = true
          }
        },
        () => {
          pdfLoading.close()
          return this.$message.error('生成报告出错！')
        }
      )
    }
  },
  beforeDestroy() {
    clearInterval(this.is_Vital_signs_timer)
    clearInterval(this.is_Vital_signs_timer2)
  }
}
</script>
