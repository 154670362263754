<style scoped lang="less">
.reportEditContent {
  background-color: #f0f0f0;
  width: 1200px;
  margin: 10px;
  border-radius: 5px;
  .info-header {
    margin: 0 10px;
    height: 2rem;
    line-height: 2rem;
    font-size: 16px;
    font-weight: bold;
  }
  .info-content {
    display: flex;
    align-items: center;
    padding: 0 10px 8px;
    border-bottom: 1px solid #ccc;
    .info-content-item {
      display: flex;
      align-items: center;
      width: 18%;
      span {
        width: 30%;
        text-align: center;
      }
      .ecgInfo {
        width: 70%;
      }
    }
    .info-content-item-two {
      display: flex;
      align-items: center;
      width: 46%;
      span {
        width: 30%;
        text-align: center;
      }
      .span-two {
        width: 35%;
      }
      .ecgInfo {
        width: 70%;
      }
    }
  }
  /deep/ .my-label {
    color: #000;
  }
  .infoTable {
    height: 7rem;
  }
  .infoTree {
    height: 18rem;
    .top-item-row {
      .item-row {
        margin: 10px 5px 0 5px;
        height: 260px;
        display: flex;
      }
    }

    .el-tab-pane {
      height: 19rem;
    }
    .infoTree-content {
      margin: 15px 0;
    }
    /deep/ .el-tabs--border-card > .el-tabs__content {
      padding: 0;
    }
  }
  .infoText {
    height: calc(19rem - 270px);
    line-height: calc(19rem - 270px);
    background-color: #f0f0f0;
  }
}
@media screen and (max-width: 1180px) {
  .reportEditContent {
    width: 1000px;
  }
}
.ecg-content {
  min-height: 3rem;
  width: 100%;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #dedede;
  .common-button-ecg {
    padding: 0.375rem 0.625rem;
    margin-left: 1rem;
  }
  .infoTitle {
    margin-right: 0.5rem;
  }
}
.medicate-table {
  height: 100%;
  width: 50%;
}
.dialog-table {
  width: 24%;
  margin-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input-form-item-content {
    margin-bottom: 5px;
  }

  .allMedicinal {
    margin-bottom: 5px;
    height: 28px;
    line-height: 28px;
    text-align: center;
  }

  .table-style /deep/ .el-table-column--selection.is-leaf .el-checkbox {
    display: none;
  }
  .el-table {
    /deep/tbody tr:hover {
      cursor: pointer;
    }
  }
}

.except-text {
  display: flex;
  align-items: center;
  margin: 15px 10px 20px 10px;
  .demonstration {
    margin: 10px;
  }
  .except-text-Btn {
    margin: 0 10px;
  }
}
.infoText {
  height: 2rem;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  .infoTextContent {
    color: red;
    margin: 0 5px;
  }
  .infoTextInput {
    display: flex;
    align-items: center;
    .el-input {
      width: 60px;
    }
    .el-icon-circle-close {
      margin-left: 5px;
    }
  }
  .el-icon-circle-close {
    color: red;
    cursor: pointer;
    font-size: 16px;
  }
  /deep/.el-input-group__append {
    background-color: beige;
  }
  /deep/ .el-input-group__prepend {
    color: red;
    background-color: #fff;
  }
  /deep/.el-input-group--append .el-input__inner {
    border-radius: 5%;
    margin-right: 5px;
  }
}
</style>
<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="病情及突发事件" v-if="isShow" @close="Close">
    <div class="ecg-content">
      <el-button type="success" class="common-button-ecg" icon="el-icon-finished" v-debounce="Save">
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button-ecg"
        style="margin-right: 0.5rem"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
      <div class="infoTitle">
        报告内容预览：
        <span v-if="specialColumnInput">{{ specialColumnInput }}，</span>
        <span>遵医嘱，给予：</span>
        <span v-for="(item, index) in reportEditData" :key="'1' + index">
          <span v-if="item.type === '介入护理'">{{ item.info }}&ensp;{{ item.volume }}</span>
        </span>
        <span v-for="(item, index) in reportEditData" :key="'2' + index">
          <span v-if="item.type === '用药'">{{ item.info }}&ensp;{{ item.volume }}</span>
        </span>
      </div>
    </div>
    <div class="reportEditContent">
      <div class="info-header" v-if="info.niReportEcg">生命体征：</div>
      <div class="info-content">
        <div class="info-content-item-two">
          <span>血压BP:</span>
          <span class="span-two">(收缩压：</span>
          <el-input
            placeholder="请输入"
            type="text"
            class="ecgInfo"
            @keyup.enter.native="nextFocus($event)"
            v-model="BB"
            size="mini"
          >
            <template slot="append">mmHg</template>
          </el-input>
          <span class="span-two">&ensp;舒张压：</span>
          <el-input
            placeholder="请输入"
            type="text"
            class="ecgInfo"
            @keyup.enter.native="nextFocus($event)"
            v-model="PP"
            size="mini"
          >
            <template slot="append">mmHg</template>
          </el-input>
          <span style="width: 0.75rem">)</span>
        </div>
        <div class="info-content-item">
          <span>心率HR:</span>
          <el-input
            placeholder="请输入"
            type="text"
            class="ecgInfo"
            @keyup.enter.native="nextFocus($event)"
            v-model="info.niReportEcg.hr"
            size="mini"
          >
            <template slot="append">bpm</template>
          </el-input>
        </div>
        <div class="info-content-item">
          <span>SPO2:</span>
          <el-input
            placeholder="请输入"
            type="text"
            class="ecgInfo"
            @keyup.enter.native="nextFocus($event)"
            v-model="info.niReportEcg.spo2"
            size="mini"
          >
            <template slot="append">%</template>
          </el-input>
        </div>
        <div class="info-content-item">
          <span>呼吸RR:</span>
          <el-input
            placeholder="请输入"
            type="text"
            class="ecgInfo"
            @keyup.enter.native="nextFocus($event)"
            v-model="info.niReportEcg.rr"
            size="mini"
          >
            <template slot="append">rpm</template>
          </el-input>
        </div>
      </div>
      <div class="info-header">特殊处理：</div>
      <div class="infoTable">
        <el-table
          :data="reportEditData"
          :header-cell-style="{ padding: 0 }"
          border
          style="width: 100%"
          height="7rem"
          highlight-current-row
        >
          <el-table-column
            label="特殊处理"
            align="center"
            width="150px"
            prop="type"
          ></el-table-column>
          <el-table-column label="详细" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.info }} {{ scope.row.volume }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150px">
            <template slot-scope="scope">
              <span
                v-if="scope.row.type === '介入护理'"
                style="color: red; cursor: pointer"
                @click="deleteItem(scope.$index)"
              >
                删除
              </span>
              <span
                v-if="scope.row.type === '用药'"
                style="color: red; cursor: pointer"
                @click="deleteItem(scope.$index)"
              >
                删除
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="reportEditContent">
      <div class="infoTree">
        <div class="top-item-row">
          <div class="item-row">
            <div class="dialog-table">
              <el-input
                class="input-form-item-content"
                placeholder="请输入突发事件描述"
                type="string"
                clearable
                v-model="specialColumnInput"
                @change="specialChange"
                size="mini"
              ></el-input>
              <el-table
                ref="specialTable"
                :header-cell-style="{ padding: 0 }"
                :data="tableData"
                style="width: 100%"
                height="calc(100% - 50px)"
                highlight-current-row
                @row-click="handleSpecial"
              >
                <el-table-column
                  header-align="center"
                  label="突发事件"
                  align="center"
                  prop="title"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
            </div>
            <div class="dialog-table">
              <div class="allMedicinal">
                <el-button
                  :type="operationTitle === 'medicine' ? 'primary' : 'default'"
                  size="mini"
                  @click="handleMedicine"
                >
                  用药
                </el-button>
                <el-button
                  :type="operationTitle === 'handle' ? 'primary' : 'default'"
                  size="mini"
                  @click="handleMedicine2"
                >
                  处理
                </el-button>
              </div>
              <el-table
                v-show="operationTitle === 'medicine'"
                class="table-style"
                ref="specialInfoTable"
                :header-cell-style="{ padding: 0 }"
                :data="comboData"
                v-loading="comboLoading"
                style="width: 100%"
                height="100%"
                highlight-current-row
                @row-click="handleComboDetail"
              >
                <el-table-column
                  header-align="center"
                  label="用药套餐"
                  align="center"
                  prop="title"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
              <el-table
                v-show="operationTitle === 'handle'"
                class="table-style"
                ref="disposeInfoTable"
                :header-cell-style="{ padding: 0 }"
                :data="disposeData"
                v-loading="comboLoading"
                style="width: 100%"
                height="100%"
                highlight-current-row
                @row-click="handleDisposeDetail"
                @row-dblclick="_doubleDisposeDetail"
              >
                <el-table-column
                  header-align="center"
                  label="处理"
                  align="center"
                  prop="title"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
            </div>
            <div class="medicate-table" v-show="operationTitle === 'medicine'">
              <el-table
                :header-cell-style="{ padding: 0 }"
                :data="transferData"
                style="width: 100%"
                height="100%"
              >
                <el-table-column
                  prop="medicineName"
                  label="药品名称"
                  show-overflow-tooltip
                  align="center"
                ></el-table-column>
                <el-table-column label="规格" align="center" width="80">
                  <template slot-scope="scope">
                    <div>{{ scope.row.medicineDose }}{{ scope.row.medicineDoseUnit }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="medicineDose"
                  label="用量"
                  show-overflow-tooltip
                  align="center"
                  width="120"
                >
                  <template slot-scope="scope">
                    <el-input
                      class="width:80px"
                      placeholder="请输入"
                      type="text"
                      v-model="scope.row.consumption"
                      size="mini"
                    >
                      <template v-if="scope.row.medicineDoseUnit" slot="append">
                        {{ scope.row.medicineDoseUnit }}
                      </template>
                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="infoText" v-show="comboDetailIsShow">
            <div class="infoTextContent">给药方式：</div>
            <div class="infoTextSelect" v-if="isSelect">
              <el-select
                v-model="useTypeId"
                filterable
                clearable
                size="mini"
                placeholder="请选择给药方式"
                @change="changeUseType"
              >
                <el-option
                  v-for="item in textList"
                  :key="item.value"
                  :label="item.useTypeOld"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </div>
            <div class="infoTextInput" v-else-if="!isSelect">
              <div v-for="(item, index) in inputList" :key="index">
                <div v-if="item.val !== null" style="display: flex; align-items: center">
                  <div v-if="item.pre" style="color: red; flex-shrink: 0">{{ item.pre }}</div>
                  <el-input
                    style="margin-left: 5px; margin-right: 2px"
                    v-model="item.val"
                    placeholder=""
                  ></el-input>
                </div>
                <div v-else style="color: red">{{ item.pre }}</div>
              </div>
              <i class="el-icon-circle-close" @click="showSelect"></i>
            </div>
            <div>
              <el-button
                type="success"
                class="common-button commonBtn"
                icon="el-icon-finished"
                @click="SaveMedicate"
              >
                <span>确认添加</span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="except-text">
      <div class="executioner">
        <span class="demonstration">执行者（可多选、可搜索）</span>
        <el-select
          value-key="label"
          v-model="executeNameId"
          multiple
          filterable
          size="mini"
          placeholder="请选择执行者"
          @change="changeExecuteName"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="execution-time">
        <div>
          <span class="demonstration">执行时间</span>
          <el-date-picker
            v-model="executeDate"
            type="datetime"
            align="center"
            placeholder="选择日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm"
          ></el-date-picker>
        </div>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { isEqual } from '../../../../utils/index'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'SpecialOperation',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      ComboSelectColumn: null,
      comboLoading: false,
      tableData: [],
      comboData: [],
      disposeData: [],
      // 套餐药品详情
      transferData: [],
      selectSpecialColumn: null,
      specialColumnInput: null,
      comboDetailIsShow: false,
      reportEditData: [],
      executeNameId: [],
      options: [],
      tempFilterOptions: [],
      executors: [],
      executeDate: null,
      selectColumn: {
        bp: '',
        hr: '',
        spo2: '',
        rr: ''
      },
      BB: '',
      PP: '',
      isSelect: true,
      f_flag: true,
      textList: [],
      inputList: [],
      useTypeId: '',
      useTypeValue: '',
      useType: '',
      info: {
        executors: [],
        niReportEcg: {
          bp: '',
          hr: '',
          spo2: '',
          rr: ''
        },
        medicationCombos: []
      },
      allDetail: {},
      inputDoms: null,
      tabs_index: '0',
      operationTitle: 'medicine',
      treatmentId: ''
    }
  },
  computed: {},
  watch: {
    isShow: {
      handler(val) {
        if (val) {
          this.getDate()
          this._initValve()
        }
      }
    }
  },
  created() {},
  methods: {
    isEqual,
    Show(val, type) {
      this.isShow = true
      if (val) {
        this.selectColumn = deepClone(val)
        if (type) {
          this.info.niReportEcg = this.selectColumn
          if (this.info.niReportEcg.bp) {
            this.BB = this.info.niReportEcg.bp.split('/')[0]
            this.PP = this.info.niReportEcg.bp.split('/')[1]
          } else {
            this.BB = this.info.niReportEcg.bp_dia
            this.PP = this.info.niReportEcg.bp_sys
          }
        }
        setTimeout(() => {
          this.changeInputDoms()
          this.inputDoms = this.changeInputDoms()
          this.$nextTick(() => {
            this.inputDoms[0].focus()
            this.inputDoms[0].select()
          })
        }, 500)
      }
    },
    changeInputDoms() {
      const inputDoms = document.querySelectorAll('.reportEditContent .ecgInfo .el-input__inner')
      inputDoms.forEach((item, index) => {
        item.setAttribute('data-index', index)
      })
      return inputDoms
    },
    nextFocus(event) {
      const index = event.target.getAttribute('data-index')
      const nextIndex = parseInt(index) + 1
      const length = this.inputDoms.length
      if (nextIndex < length) {
        this.inputDoms[nextIndex].focus()
        this.inputDoms[nextIndex].select()
      } else {
        this.inputDoms[0].focus()
        this.inputDoms[0].select()
      }
    },
    handleClick(tab) {
      this.tabs_index = tab.index
    },
    // 用药记录
    changeUseType(val) {
      for (let i = 0; i < this.textList.length; i++) {
        const element = this.textList[i]
        if (val === element.uuid) {
          const s = element.useTypeOld
          if (s.includes('|')) {
            this.getList(s)
          } else {
            this.inputList.push({
              pre: s,
              val: null
            })
          }
        }
      }
      this.isSelect = !this.isSelect
    },
    showSelect() {
      this.useTypeId = ''
      this.inputList = []
      this.isSelect = !this.isSelect
    },
    getInputList() {
      this.$api.get('/v1/webconsole/medication/usetype/list').then(
        (res) => {
          if (res.data && res.data.data) {
            this.textList = res.data.data
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    getList(s) {
      var k, i
      for (i = 0, k = 0; i < s.length; i++) {
        if (s[i] == '|' && i + 1 < s.length) {
          // let j = i + 1
          this.inputList.push({
            pre: s.substr(k, i - k),
            val: ''
          })
          k = i + 1
        } else {
          if (i + 1 === s.length) {
            this.inputList.push({
              pre: s.substr(k, s.length - k),
              val: null
            })
          }
        }
      }
    },
    getDate() {
      var time = new Date()
      time.setHours(time.getHours() + 8)
      time = time.toISOString()
      time = time.replace('T', ' ')
      time = time.substr(0, 19)
      this.executeDate = time
    },
    changeExecuteName(val) {
      this.executors = []
      for (let j = 0; j < val.length; j++) {
        const ele = val[j]
        for (let i = 0; i < this.tempFilterOptions.length; i++) {
          const element = this.tempFilterOptions[i]
          if (ele === element.userID) {
            this.executors.push(element)
          }
        }
      }
    },
    getUserName() {
      this.executeNameId = []
      this.$api.get('/v1/webconsole/user/me').then(
        (res) => {
          if (res.data && res.data.data) {
            for (let i = 0; i < this.tempFilterOptions.length; i++) {
              const element = this.tempFilterOptions[i]
              if (res.data.data.userID === element.userID) {
                this.executeNameId.push(res.data.data.userID)
                this.changeExecuteName(this.executeNameId)
              }
            }
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    // 初始化数据
    _initValve() {
      this.$api.get(`/v1/webconsole/operate/puboperate/list?keyword=`).then((res) => {
        if (res.data && res.data.data) {
          this.tableData = res.data.data.records
        }
      })
      this.getInputList()
      this.getUserName()
      this.$api.get('/v1/webconsole/study/nurses').then(
        (res) => {
          if (res.data && res.data.data) {
            this.tempFilterOptions = res.data.data
            let arr = []
            res.data.data.map((item) => {
              arr.push({
                label: item.userName,
                value: item.userID
              })
            })
            this.options = arr
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    specialChange(e) {
      // 先清空选中
      this.selectSpecialColumn = null
      // 清空选中套餐
      this.comboData = []
      this.disposeData = []
      this.transferData = []
      this.inputList = []
      this.ComboSelectColumn = null
      this.$refs.specialTable.setCurrentRow()
      this.$api.get(`/v1/webconsole/operate/puboperate/list?keyword=${e}`).then((res) => {
        if (res.data && res.data.data) {
          this.tableData = res.data.data.records
          this.tableData.map((item) => {
            if (item.title === e) {
              this.$refs.specialTable.setCurrentRow(item, true)
              this.handleSpecial(item)
            }
          })
        }
      })
    },
    // 单击特殊处理
    handleSpecial(row) {
      // 清空药品详情
      this.comboData = []
      this.disposeData = []
      this.transferData = []
      this.comboLoading = true
      // 清空选中套餐
      this.ComboSelectColumn = null
      this.specialColumnInput = row.title
      if (this.isEqual(this.selectSpecialColumn, row)) {
        this.selectSpecialColumn = null
        this.specialColumnInput = null
        this.$refs.specialTable.setCurrentRow()
      }
      setTimeout(() => {
        if (this.specialColumnInput) {
          this.selectSpecialColumn = row
          this.searchMedicateCombo()
        }
      }, 50)
      setTimeout(() => {
        this.comboLoading = false
      }, 200)
    },

    // 查询套餐
    searchMedicateCombo() {
      this.$api
        .get(`/v1/webconsole/operate/packages/${this.selectSpecialColumn.uuid}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.comboData = res.data.data
            if (this.selectColumn?.medicationPackageId) {
              this.comboData.map((item) => {
                if (item.uuid == this.selectColumn.medicationPackageId) {
                  this.$refs.specialInfoTable.setCurrentRow(item, true)
                  this.handleComboDetail(item)
                }
              })
            }
          }
        })
      this.$api
        .get(`/v1/webconsole/operate/treatment/${this.selectSpecialColumn.uuid}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.disposeData = res.data.data
          }
        })
    },
    handleMedicine() {
      this.operationTitle = 'medicine'
      this.treatmentId = ''
      this.specialColumnInput = null
      this.selectSpecialColumn = null
      this.$refs.specialTable.setCurrentRow()

      this.inputList = []
      this.disposeData = []
      this.comboData = []
    },
    handleMedicine2() {
      this.operationTitle = 'handle'
      this.treatmentId = ''
      this.specialColumnInput = null
      this.selectSpecialColumn = null
      this.$refs.specialTable.setCurrentRow()
      this.comboDetailIsShow = false

      this.inputList = []
      this.disposeData = []
      this.comboData = []
    },
    // 单击套餐
    handleComboDetail(row) {
      // 先清空药品
      this.transferData = []

      if (this.isEqual(this.ComboSelectColumn, row)) {
        this.$refs.specialInfoTable.setCurrentRow()
        this.ComboSelectColumn = null
      }
      this.ComboSelectColumn = row

      this.inputList = []
      this.useTypeId = ''
      this.comboDetailIsShow = true
      this.isSelect = true
      this.searchComboDetail(row)
    },
    // 单击套餐
    handleDisposeDetail(row) {
      // 先清空药品
      this.transferData = []

      if (this.isEqual(this.ComboSelectColumn, row)) {
        this.$refs.disposeInfoTable.setCurrentRow()
        this.ComboSelectColumn = null
      }
      this.ComboSelectColumn = row
    },
    _doubleDisposeDetail(val) {
      const newElement = {
        type: '处理',
        info: this.specialColumnInput
          ? this.specialColumnInput + '使用' + val.title
          : '使用' + val.title,
        volume: '',
        uuid: this.reportEditData.length + 1000
      }

      if (this.reportEditData.length > 0) {
        let found = false
        this.reportEditData.forEach((element, index) => {
          if (element.type === '处理') {
            this.$set(this.reportEditData, index, newElement)
            found = true
          }
        })

        if (!found) {
          this.reportEditData.push(newElement)
        }
      } else {
        this.reportEditData = [newElement]
      }
      this.treatmentId = val.uuid
    },
    // 查询套餐详情
    searchComboDetail(row) {
      this.$api.get(`/v1/webconsole/medication/packages/medicines/${row.uuid}`).then((res) => {
        if (res.data && res.data.data) {
          this.transferData = res.data.data
        }
      })
    },
    // 删除特殊处理
    deleteItem(index) {
      if (this.reportEditData[index].type === '用药') {
        for (let i = 0; i < this.info.medicationCombos.length; i++) {
          const element = this.info.medicationCombos[i]
          if (this.reportEditData[index].uuid === element.uuid) {
            this.info.medicationCombos.splice(i, 1)
            break
          }
        }
      }
      this.reportEditData.splice(index, 1)
      this.$message.success('删除成功')
    },
    SaveMedicate() {
      this.useTypeValue = ''
      this.useType = ''
      this.inputList.forEach((item) => {
        if (item.val === null) {
          this.useType = this.useType + item.pre
        } else if (item.val !== null) {
          this.useTypeValue = this.useTypeValue + '#' + item.val
          this.useType = this.useType + item.pre + item.val
          if (item.val === '') {
            this.f_flag = false
          }
        }
      })
      var arr = this.useTypeValue.split('#').filter(Boolean)
      let useTypeArgs = {}
      this.textList.forEach((item) => {
        if (this.useTypeId === item.uuid) {
          if (item.vars) {
            item.vars.forEach((v, index) => {
              useTypeArgs[v.varname] = arr[index]
            })
          }
        }
      })
      if (!this.f_flag) {
        this.f_flag = !this.f_flag
        return this.$message.error('有未填项,无法保存')
      }
      if (!this.useTypeId) {
        return this.$message.error('请选择给药方式')
      }
      this.info.medicationCombos = [
        {
          useType: this.useType,
          useTypeId: this.useTypeId,
          useTypeValue: this.useTypeValue,
          useTypeArgs: useTypeArgs,
          medicationPackageId: this.transferData[0].packageId,
          niOperateTitle: this.specialColumnInput ? this.specialColumnInput : '',
          medicationPackageDetails: deepClone(this.transferData)
        }
      ]
      if (this.transferData.length > 0) {
        let label = ''
        for (let j = 0; j < this.transferData.length; j++) {
          const ele = this.transferData[j]
          label = label + ele.medicineName + ele.consumption + ele.consumptionUnit + ' '
          if (!ele.consumption) {
            this.f_flag = false
          }
        }
        if (!this.f_flag) {
          this.f_flag = !this.f_flag
          return this.$message.error('有未填项,无法保存')
        }

        const newElement = {
          type: '用药',
          info: this.specialColumnInput ? this.specialColumnInput + '使用' + label : '使用' + label,
          volume: this.useType,
          uuid: this.reportEditData.length + 1000
        }

        if (this.reportEditData.length > 0) {
          let found = false
          this.reportEditData.forEach((element, index) => {
            if (element.type === '用药') {
              this.$set(this.reportEditData, index, newElement)
              found = true
            }
          })

          if (!found) {
            this.reportEditData.push(newElement)
          }
        } else {
          this.reportEditData = [newElement]
        }

        this.$message.success('添加成功')
      }
      console.log(this.info.medicationCombos)
    },
    // 保存
    Save() {
      if (this.BB !== '' && this.PP === '') {
        return this.$message({
          message: '请填写舒张压',
          type: 'warning'
        })
      }
      if (this.PP !== '' && this.BB === '') {
        return this.$message({
          message: '请填写收缩压',
          type: 'warning'
        })
      }
      if (this.PP !== '' && this.BB !== '') {
        this.info.niReportEcg.bp = this.BB + '/' + this.PP
        this.info.niReportEcg.bp_dia = this.BB
        this.info.niReportEcg.bp_sys = this.PP
      }
      if (this.executors.length === 0) {
        return this.$message.error('请选择执行者')
      }
      if (this.executeDate === '') {
        return this.$message.error('请填写执行时间')
      }
      if (this.executeDate === '') {
        return this.$message.error('请填写执行时间')
      }

      this.info.executors = this.executors

      console.log(this.info)

      if (this.info.medicationCombos.length > 0) {
        let data = {
          pubOperateId: this.selectSpecialColumn.uuid,
          treatmentId: this.treatmentId,
          ecg: {
            hr: this.info.niReportEcg.hr,
            bp_dia: this.info.niReportEcg.bp_dia,
            bp_sys: this.info.niReportEcg.bp_sys,
            rr: this.info.niReportEcg.rr,
            spo2: this.info.niReportEcg.spo2,
            time: this.info.niReportEcg.time
          },
          medicine: {
            medicationPackageId: this.info.medicationCombos[0].medicationPackageId,
            num: 1,
            pkgs: [],
            useTypeId: this.info.medicationCombos[0].useTypeId,
            useTypeArgs: this.info.medicationCombos[0].useTypeArgs,
            executeNames: [],
            executeTime: this.executeDate
          },
          executeNames: [],
          executeTime: this.executeDate
        }

        this.info.executors.forEach((element) => {
          data.executeNames.push(element.userName)
          data.medicine.executeNames.push(element.userName)
        })

        this.info.medicationCombos[0].medicationPackageDetails.forEach((ele) => {
          data.medicine.pkgs.push({
            medicineId: ele.medicineId,
            num: Number(ele.consumption)
          })
        })

        console.log(data)

        this.$api
          .post(`/v1/webconsole/operate/do/${this.$route.query.operationId}?stage=sz`, data)
          .then((res) => {
            this.$message.success(res.data.msg)
            this.comboDetailIsShow = false
            this.isShow = false
            // 套餐list清空 防止再次打开还有数据
            this.comboData = []
            this.disposeData = []
            if (this.comboData?.length > 0) {
              this.$refs.specialInfoTable[0].setCurrentRow()
            }
            // 用药list清空
            this.transferData = []
            // 选中的套餐清空
            this.executeNameId = []
            this.textList = []
            this.inputList = []
            this.reportEditData = []
            this.allDetail = {}
            this.executors = []
            this.info = {
              executors: [],
              niReportEcg: {
                bp: '',
                hr: '',
                spo2: '',
                rr: ''
              },
              medicationCombos: []
            }
            this.ComboSelectColumn = null
            this.specialColumnInput = null
            this.treatmentId = ''
            this.selectColumn = {
              bp: '',
              hr: '',
              spo2: '',
              rr: ''
            }
            this.$emit('dialogSave', this.selectSpecialColumn)
          })
      } else {
        return this.$message.error('请填写突发事件用药')
      }
    },
    Close() {
      this.comboDetailIsShow = false
      this.isShow = false
      // 套餐list清空 防止再次打开还有数据
      this.comboData = []
      this.disposeData = []
      if (this.comboData?.length > 0) {
        this.$refs.specialInfoTable.setCurrentRow()
      }
      // 用药list清空
      this.transferData = []
      // 选中的套餐清空
      this.executeNameId = []
      this.textList = []
      this.inputList = []
      this.reportEditData = []
      this.allDetail = {}
      this.executors = []
      this.info = {
        executors: [],
        niReportEcg: {
          bp: '',
          hr: '',
          spo2: '',
          rr: ''
        },
        medicationCombos: []
      }
      this.ComboSelectColumn = null
      this.specialColumnInput = null
      this.treatmentId = ''
      this.selectColumn = {
        bp: '',
        hr: '',
        spo2: '',
        rr: ''
      }
    }
  }
}
</script>
